export default {
    navList: {
        nav1: 'Inicio',
        nav2: 'Ventajas',
        nav3: 'Portafolio',
        nav4: 'Videos AI',
        nav5: 'Contacto',
        login: 'Iniciar Sesión',
        register: 'Registrarse'
    },
    Language: "Idioma",
    down: 'Descargar',
    Menu: 'Menú',
    bannerTitle: '[SGC AI] Trading Cuantitativo',
    bannerTitle1: '[SGC AI]',
    bannerTitle2: 'Trading',
    bannerTitle3: 'Cuantitativo',
    bannerSubtitle: 'Rendimiento Estable',
    bannerListTxt1: 'Reequilibrio',
    bannerListTxt2: 'Gestión de Portafolio',
    bannerListTxt3: 'Recolección de Datos',
    bannerListTxt4: 'Enrutamiento Inteligente',
    bannerDownBtn: 'Descargar Android',
    bannerSubscribeBtn: 'Suscribirse Ahora',
    ProductAdvantages: 'Ventajas vs Humanas',
    advantagesList: {
        title1: 'La diferencia entre las ventajas del producto y la mano de obra',
        describe1: 'Las ventajas de diseño de los modelos de estrategia cuantitativa de IA. Cada modelo cuantitativo de IA se compone de más de cientos de factores que pueden afectar el aumento y la caída de las acciones. Tiene capacidades de aprendizaje profundo independientes y puede mantenerse al día con el flujo principal de fondos. Es difícil que el análisis y la investigación manuales cumplan con tales requisitos.',
        title2: 'Ventajas de la función de prueba de devolución de datos históricos',
        describe2: 'Para diseñar un modelo cuantitativo de IA con una tasa de retorno que supere con creces la tasa de retorno del mercado, puede obtener la tasa de retorno anualizada histórica del modelo realizando una prueba retrospectiva de los datos del ciclo histórico. Entre ellos se puede seleccionar el modelo más ideal. Esto es simplemente imposible de hacer manualmente, por mucho tiempo que lleve. Por lo tanto, las operaciones manuales a menudo no son rigurosas ni poco científicas, y solo se basan en sentimientos y experiencia personales para seleccionar los objetivos para la operación.',
        title3: 'Ventajas del escaneo automático de más de 400.000 acciones en el mercado global',
        describe3: 'El robot de IA escanea automáticamente más de 400.000 acciones en todo el mercado a lo largo del día de acuerdo con las condiciones y otros parámetros establecidos por los técnicos de modelos cuantitativos, y completa la operación inmediatamente cuando descubre que se cumplen las condiciones objetivo establecidas. Sólo lleva unos segundos. El análisis manual llevaría años o incluso décadas y no sería capaz de completar la misma cantidad de trabajo.',
        title4: 'Sólo entendiendo la historia podremos juzgar el futuro con mayor precisión',
        describe4: 'La tasa de retorno de la prueba histórica de datos del modelo cuantitativo se puede utilizar como una mejor referencia para juzgar las tasas de retorno futuras. Los ingenieros técnicos solo necesitan ingresar parámetros directamente en la dirección de las acciones compradas ese día, y pueden probar la tasa de retorno diaria. de las acciones en el punto de compra. Para cumplir con estos requisitos, la operación manual es simplemente imposible.',
        title5: 'Establecer un modelo de ganancias estable y a largo plazo',
        describe5: 'Para obtener ganancias estables a largo plazo y un enorme interés compuesto, los ingenieros técnicos pueden personalizar y agregar sus propios parámetros de indicador seleccionados, verificar la tasa de rendimiento mediante pruebas retrospectivas, seleccionar la estrategia o combinación más ideal y luego realizar operaciones completamente automáticas con robots de inteligencia artificial. Este tipo de trabajo no se puede realizar manualmente.',
        title6: 'Capacidad de ejecución operativa',
        describe6: 'La ventaja del comercio cuantitativo totalmente automático de SGC AI es que utiliza herramientas racionales para operar transacciones de acuerdo con la configuración del modelo. El robot no tiene cambios emocionales y puede superar la naturaleza humana, la codicia y el miedo humanos y muchas otras deficiencias y debilidades en el proceso de operación. Las operaciones manuales a menudo se ven afectadas por el alza y la caída del mercado y varios otros factores, y no pueden operarse de acuerdo con el mejor modelo de ganancias durante mucho tiempo.',
        title7: 'Liberar completamente la productividad',
        describe7: 'El robot cuantitativo SGC AI se puede configurar una vez y realizar operaciones automáticamente de acuerdo con la configuración durante un largo tiempo. Solo necesita llevar a cabo el proceso de asignación de capital una vez al día, lo que puede liberar completamente a los usuarios del arduo trabajo de la negociación de acciones. Sin embargo, el análisis y la investigación manuales, el seguimiento del mercado y las operaciones manuales encomendadas requieren mucho tiempo y energía por parte de los inversores.',
    },
    investmentPackage: "Elija su Portafolio",
    versionTab1: 'Robot 1 estrella',
    versionTab2: 'Robot 2 estrella',
    versionTab3: 'Robot 3 estrella',
    versionTab4: 'Robot 4 estrella',
    versionTab5: 'Robot 5 estrella',
    versionTab6: 'SGC VIP Robot',
    versionTab7: 'Personalizado',
    BusinessDocking: 'Contacto Comercial',
    email: "Correo",
    officialEmail: 'Correo Oficial',
    privacyPolicy: 'Política de Privacidad',
    TermOfService: "Términos de Servicio",
    Copyright: 'Derechos de Autor'
}