

const packListData = {
    'zh': {
        listdata: [
            [
                {
                    title: "基础版AI",
                    illustrate1: '要求配资金额：100美元-300美元',
                    illustrate2: '日利率：0.44%-0.45%',
                    illustrate3: '月利率:约13.2%-13.5%',
                    illustrate4: '签约：15日',
                    introduce: '【基础版AI】开放是为了新手第一次使用给与体验，可以体验公司的产品带来的回报率效果和稳定性签约成功后需要每日进行配资才能获得收益投资100USD，15日回报收益约为7USD或更多投资300USD，15日回报收益约为21USD或更多投资金额可在100至300数字任意之间选择'
                }
            ],
            [
                {
                    title: "入门版AI",
                    illustrate1: '要求配资金额：500USD-1000USD',
                    illustrate2: '日利率：0.48%-0.50%',
                    illustrate3: '月利率:14.4%-15%',
                    illustrate4: '签约：30日 ',
                    introduce: '签约成功后需要每日进行配资才能获得收益投资500USD，30日回报收益大约72USD或更多投资1000USD,30日回报收益大约144USD或更多投资金额可在500至1000数字之间任意选择'
                }
            ],
            [
                {
                    title: "标准版AI",
                    illustrate1: '要求配资金额：1000USD-3000usd',
                    illustrate2: '日利率：0.60%-0.65%',
                    illustrate3: '月利率:18%-19.5%',
                    illustrate4: '签约：30日 ',
                    introduce: '签约成功后需要每日进行配资才能获得收益投资1000USD，30日回报收益大约180USD或更多投资3000USD,30日回报收益大约540USD或更多投资金额可在1000至3000数字之间任意选择,30日回报收益大约144USD或更多投资金额可在500至1000数字之间任意选择'
                }
            ],
            [
                {
                    title: "高级版AI",
                    illustrate1: '要求配资金额：3000USD-10000USD',
                    illustrate2: '日利率：0.70%-0.75%',
                    illustrate3: '月利率:21%-22.5%',
                    illustrate4: '签约：30日',
                    introduce: '要求配资金额：3000USD-10000USD日利率：0.70%-0.75%月利率:21%-22.5%合约：30日 签约成功后需要每日进行配资才能获得收益投资3000USD，30日回报收益大约630USD或更多投资10000USD，30日获得回报收益大约2100USD或更多投资金额可在3000至10000数字之间任意选择'
                }


            ],
            [{
                title: "专业版AI",
                illustrate1: '要求配资金额：10000USD-30000USD',
                illustrate2: '日利率：0.80%-0.85%',
                illustrate3: '月利率:24%-25.5%',
                illustrate4: '签约：30日',
                introduce: '签约成功后需要每日进行配资才能获得收益投资10000USD，30日回报收益大约2400USD或更多投资30000USD，30日获得回报收益大约7200USD或更多投资金额可在10000至30000数字之间任意选择'
            }
            ],
            [{
                title: "企业版AI",
                illustrate1: '要求配资金额：30000USD-50000USD',
                illustrate2: '日利率：0.90%-0.95%',
                illustrate3: '月利率:27%-28.5%',
                illustrate4: '签约：30日',
                introduce: '投资30000USD，30日回报收益大约8100USD或更多投资50000USD，30日获得回报收益大约13500USD或更多投资金额可在30000至50000数字之间任意选择'
            }],
            [
                {
                    title: "定制版AI",
                    illustrate1: '要求配资金额：50000-200000',
                    illustrate2: '日利率：0.01-0.012',
                    illustrate3: '月利率:30%-36%',
                    illustrate4: '签约：30日',
                    introduce: ' 签约成功后需要每日进行配资才能获得收益投资50000USD，30日回报收益大约15000USD或更多投资200000USD，30日获得回报收益大约60000USD或更多投资金额可在50000至200000数字之间任意选择'
                }
            ]
        ]
    },
    'en': {
        listdata: [
            [
                {
                    title: "Basic AI",
                    illustrate1: 'Required allocation amount: US$100-US$300',
                    illustrate2: 'Daily interest rate: 0.44%-0.45%',
                    illustrate3: 'Monthly interest rate: about 13.2%-13.5%',
                    illustrate4: 'Signing: 15th',
                    introduce: "[Basic version of AI] is open for novices to experience when using it for the first time. They can experience the rate of return effect and stability brought by the company's products. After successful signing, you need to allocate funds every day to get income. Invest 100USD, and the income will be returned on the 15th. About 7USD or more investment of 300USD, 15-day return income is about 21USD or more. The investment amount can be chosen between 100 and 300 numbers."
                }
            ],
            [
                {
                    title: "Entry Edition AI",
                    illustrate1: 'Required allocation amount: 500USD-1000USD',
                    illustrate2: 'Daily interest rate: 0.48%-0.50%',
                    illustrate3: 'Monthly interest rate: 14.4%-15%',
                    illustrate4: 'Signing time: 30 days ',
                    introduce: 'After successfully signing the contract, allocate funds every day to get income. The investment requires 500USD. The return income on 30 days is about 72USD or more. Invest 1,000USD. The return income on 30th day is about 144USD or more. The investment amount can be chosen between 500 and 1,000.'
                }
            ],
            [
                {
                    title: "Standard version AI",
                    illustrate1: 'Required allocation amount: 1000USD-3000usd',
                    illustrate2: 'Daily interest rate: 0.60%-0.65%',
                    illustrate3: 'Monthly interest rate: 18%-19.5%',
                    illustrate4: 'Signing: 30 days ',
                    introduce: 'After successfully signing the contract, you need to allocate funds every day to get the income. Invest 1,000USD. The return income on the 30th day is about 180USD or more. Invest 3,000USD. The return income on the 30th day is about 540USD or more. The investment amount can be chosen between 1,000 and 3,000 numbers. 30 The daily return income is about 144USD or more. The investment amount can be chosen between 500 and 1000 numbers.'
                }
            ],
            [
                {
                    title: "Advanced AI",
                    illustrate1: 'Required allocation amount: 3000USD-10000USD',
                    illustrate2: 'Daily interest rate: 0.70%-0.75%',
                    illustrate3: 'Monthly interest rate: 21%-22.5%',
                    illustrate4: 'Signing: 30 days',
                    introduce: 'Required allocation amount: 3000USD-10000USD Daily interest rate: 0.70%-0.75% Monthly interest rate: 21%-22.5% Contract: After successful signing on the 30th, daily allocation is required to obtain income. Invest 3000USD, and the return income on the 30th will be approximately 630USD or If you invest more than 10,000USD, you will get returns in 30 days. The investment amount is about 2,100USD or more. You can choose any number between 3,000 and 10,000.'
                }


            ],
            [{
                title: "Professional version AI",
                illustrate1: 'Required allocation amount: 10,000USD-30,000USD',
                illustrate2: 'Daily interest rate: 0.80%-0.85%',
                illustrate3: 'Monthly interest rate: 24%-25.5%',
                illustrate4: 'Signing: 30 days',
                introduce: 'After successfully signing the contract, you need to allocate funds every day to get the income. Invest 10,000USD. The return on the 30th will be about 2,400USD or more. Invest 30,000USD, and the return on the 30th will be about 7,200USD or more. The investment amount can be chosen between 10,000 and 30,000.'
            }
            ],
            [{
                title: "Enterprise Edition AI",
                illustrate1: 'Required allocation amount: 30,000USD-50,000USD',
                illustrate2: 'Daily interest rate: 0.90%-0.95%',
                illustrate3: 'Monthly interest rate: 27%-28.5%',
                illustrate4: 'Signing: 30 days',
                introduce: 'Invest 30,000USD and get a return of about 8,100USD or more in 30 days. Invest 50,000USD and get a return of about 13,500USD or more in 30 days. The investment amount can be chosen between 30,000 and 50,000.'
            }],
            [
                {
                    title: "Customized version of AI",
                    illustrate1: 'Required allocation amount: 50,000-200,000',
                    illustrate2: 'Daily interest rate: 0.01-0.012',
                    illustrate3: 'Monthly interest rate: 30%-36%',
                    illustrate4: 'Signing: 30 days',
                    introduce: 'After successfully signing the contract, you need to allocate funds every day to get the income. Invest 50,000USD. The return on the 30th will be about 15,000USD or more. Invest 200,000USD. The return on the 30th will be about 60,000USD or more. The investment amount can be chosen between 50,000 and 200,000.'
                }
            ]
        ]
    },
    'de': {
        listdata: [
            [
                {
                    title: "Grundlegende AI",
                    illustrate1: 'Erforderlicher Zuteilungsbetrag: 100–300 US-Dollar',
                    illustrate2: 'Täglicher Zinssatz: 0,44 %–0,45 %',
                    illustrate3: 'Monatlicher Zinssatz: ca. 13,2 %-13,5 %',
                    illustrate4: 'Unterzeichnung: 15',
                    introduce: '[Die Basisversion von AI] steht Anfängern offen, wenn sie sie zum ersten Mal verwenden. Sie können die Rendite und Stabilität der Produkte des Unternehmens erleben. Nach erfolgreicher Unterzeichnung müssen Sie jeden Tag Mittel bereitstellen, um Einnahmen zu erzielen . Investieren Sie 100 USD und das Einkommen wird am 15. zurückerstattet. Bei einer Investition von 300 USD beträgt die Rendite nach 15 Tagen etwa 21 USD oder mehr. Der Investitionsbetrag kann zwischen 100 und 300 betragen.'
                }
            ],
            [
                {
                    title: "Entry Edition AI",
                    illustrate1: 'Erforderlicher Zuteilungsbetrag: 500 USD–1.000 USD',
                    illustrate2: 'Täglicher Zinssatz: 0,48 % – 0,50 %',
                    illustrate3: 'Monatlicher Zinssatz: 14,4 %–15 %',
                    illustrate4: 'Unterzeichnung: 30 Tage',
                    introduce: 'Nach erfolgreicher Unterzeichnung des Vertrags müssen Sie jeden Tag Mittel bereitstellen, um 500 USD zu verdienen. Die Rendite beträgt nach 30 Tagen mindestens 144 USD Der Betrag kann zwischen 500 und 1000 gewählt werden.'
                }
            ],
            [
                {
                    title: "Standardversion AI",
                    illustrate1: 'Erforderlicher Zuteilungsbetrag: 1000 USD-3000 USD',
                    illustrate2: 'Täglicher Zinssatz: 0,60 %-0,65 %',
                    illustrate3: 'Monatlicher Zinssatz: 18 %–19,5 %',
                    illustrate4: 'Unterzeichnung: 30 Tage',
                    introduce: 'Nach erfolgreicher Vertragsunterzeichnung müssen Sie jeden Tag Mittel bereitstellen, um das Einkommen von 1.000 USD oder mehr zu erzielen mehr. Der Anlagebetrag kann zwischen 1.000 und 3.000 Zahlen gewählt werden. Die tägliche Rendite beträgt ca. 144 USD oder mehr.'
                }
            ],
            [
                {
                    title: "Fortschrittliche AI",
                    illustrate1: 'Erforderlicher Zuteilungsbetrag: 3.000 USD–10.000 USD',
                    illustrate2: 'Täglicher Zinssatz: 0,70 %–0,75 %',
                    illustrate3: 'Monatlicher Zinssatz: 21 %–22,5 %',
                    illustrate4: 'Unterzeichnung: 30 Tage',
                    introduce: 'Erforderlicher Zuteilungsbetrag: 3.000 USD – 10.000 USD. Täglicher Zinssatz: 0,70 % – 0,75 %. Monatlicher Zinssatz: 21 % – 22,5 %. Vertrag: Nach erfolgreicher Unterzeichnung am 30. ist eine tägliche Zuteilung erforderlich, um 3.000 USD zu investieren und die Rendite zu erzielen der 30. beträgt etwa 630 USD oder wenn Sie mehr als 10.000 USD investieren, erhalten Sie innerhalb von 30 Tagen eine Rendite. Der Investitionsbetrag beträgt etwa 2.100 USD oder mehr. Sie können eine beliebige Zahl zwischen 3.000 und 10.000 wählen.'
                }


            ],
            [{
                title: "Professionelle Version der AI",
                illustrate1: 'Erforderlicher Zuteilungsbetrag: 10.000 USD–30.000 USD',
                illustrate2: 'Täglicher Zinssatz: 0,80 %-0,85 %',
                illustrate3: 'Monatlicher Zinssatz: 24 %–25,5 %',
                illustrate4: 'Unterzeichnung: 30 Tage',
                introduce: 'Nach erfolgreicher Unterzeichnung des Vertrags müssen Sie jeden Tag Mittel bereitstellen, um das Einkommen von 10.000 USD zu erzielen. Die Rendite beträgt am 30. USD oder mehr, und die Rendite am 30. USD beträgt etwa 7.200 USD Der Anlagebetrag kann zwischen 10.000 und 30.000 gewählt werden.'
            }
            ],
            [{
                title: "Enterprise Edition AI",
                illustrate1: 'Erforderlicher Zuteilungsbetrag: 30.000 USD–50.000 USD',
                illustrate2: 'Täglicher Zinssatz: 0,90 %–0,95 %',
                illustrate3: 'Monatlicher Zinssatz: 27 %–28,5 %',
                illustrate4: 'Unterzeichnung: 30 Tage',
                introduce: 'Investieren Sie 30.000 USD und erzielen Sie in 30 Tagen eine Rendite von ca. 8.100 USD oder mehr. Investieren Sie 50.000 USD und erzielen Sie in 30 Tagen eine Rendite von ca. 13.500 USD.'
            }],
            [
                {
                    title: "Angepasste Version von AI",
                    illustrate1: 'Erforderlicher Zuteilungsbetrag: 50.000-200.000',
                    illustrate2: 'Tageszinssatz: 0,01-0,012',
                    illustrate3: 'Monatlicher Zinssatz: 30 %–36 %',
                    illustrate4: 'Unterzeichnung: 30 Tage',
                    introduce: 'Nach erfolgreicher Unterzeichnung des Vertrags müssen Sie jeden Tag Mittel bereitstellen, um 50.000 USD zu erhalten. Die Rendite beträgt am 30. USD oder mehr Der Anlagebetrag kann zwischen 50.000 und 200.000 gewählt werden.'
                }
            ]
        ]
    },
    'fr': {
        listdata: [
            [
                {
                    title: "Version de base AI",
                    illustrate1: "Montant de l'allocation requis : 100 - 300 USD",
                    illustrate2: "Taux d'intérêt journalier : 0,44 %-0,45 %",
                    illustrate3: "Taux d'intérêt mensuel : environ 13,2%-13,5%",
                    illustrate4: "Signature : 15",
                    introduce: "[La version de base de l'IA] est ouverte aux novices lorsqu'ils l'utilisent pour la première fois. Ils peuvent découvrir le taux de rendement et la stabilité apportés par les produits de l'entreprise. Après une signature réussie, vous devez allouer des fonds chaque jour pour obtenir des revenus. . Investissez 100 USD et le revenu sera restitué le 15. Environ 7 USD ou plus d'investissement de 300 USD, le revenu de retour sur 15 jours est d'environ 21 USD ou plus. Le montant de l'investissement peut être choisi entre 100 et 300 chiffres."
                }
            ],
            [
                {
                    title: "Commencer AI",
                    illustrate1: "Montant de l'allocation requis : 500 USD à 1 000 USD",
                    illustrate2: "Taux d'intérêt journalier : 0,48 %-0,50 %",
                    illustrate3: "Taux d'intérêt mensuel : 14,4 %-15 %",
                    illustrate4: "Signature : 30 jours",
                    introduce: "Après avoir signé le contrat avec succès, vous devez allouer des fonds chaque jour pour obtenir le revenu. Investissez 500 USD. Le retour sur 30 jours sera d'environ 72 USD ou plus. Investissez 1 000 USD. Le retour sur 30 jours sera d'environ 144 USD ou plus. le montant peut être choisi entre 500 et 1000."
                }
            ],
            [
                {
                    title: "Version standard AI",
                    illustrate1: "Montant de l'allocation requis : 1 000 USD à 3 000 USD",
                    illustrate2: "Taux d'intérêt journalier : 0,60 %-0,65 %",
                    illustrate3: "Taux d'intérêt mensuel : 18%-19,5%",
                    illustrate4: "Signature : 30 jours",
                    introduce: "Après avoir signé le contrat avec succès, vous devez allouer des fonds chaque jour pour obtenir le revenu. Investissez 1 000 USD. Le revenu de retour au 30ème jour est d'environ 180 USD ou plus. Investissez 3 000 USD ou plus. plus. Le montant de l'investissement peut être choisi entre 1 000 et 3 000 numéros. 30 Le revenu de rendement quotidien est d'environ 144 USD ou plus. Le montant de l'investissement peut être choisi entre 500 et 1 000 numéros."
                }
            ],
            [
                {
                    title: "Version premium AI",
                    illustrate1: "Montant de l'allocation requis : 3 000 USD à 10 000 USD",
                    illustrate2: "Taux d'intérêt journalier : 0,70 %-0,75 %",
                    illustrate3: "Taux d'intérêt mensuel : 21 %-22,5 %",
                    illustrate4: "Signature : 30 jours",
                    introduce: "Montant d'allocation requis : 3 000 USD - 10 000 USD Taux d'intérêt quotidien : 0,70 % - 0,75 % Taux d'intérêt mensuel : 21 % - 22,5 % Contrat : Après une signature réussie le 30, une allocation quotidienne est requise pour obtenir un revenu de 3 000 USD et le retour des revenus. le 30 sera d'environ 630 USD ou si vous investissez plus de 10 000 USD, vous obtiendrez des retours dans 30 jours. Le montant de l'investissement est d'environ 2 100 USD ou plus. Vous pouvez choisir n'importe quel nombre entre 3 000 et 10 000."
                }


            ],
            [{
                title: "Version professionnelle AI",
                illustrate1: "Montant de l'allocation requis: 10000 USD à 30 000 USD",
                illustrate2: "Taux d'intérêt journalier: 0,80%-0,85 %",
                illustrate3: "Taux d'intérêt mensuel : 24 %-25,5 %",
                illustrate4: "Signature : 30 jours",
                introduce: "Après avoir signé le contrat avec succès, vous devez allouer des fonds chaque jour pour obtenir un revenu. Investissez 10 000 USD. Le rendement du 30 sera d'environ 2 400 USD ou plus. Investissez 30 000 USD, et le rendement du 30 sera d'environ 7 200 USD. ou plus. Le montant de l'investissement peut être choisi entre 10 000 et 30 000."
            }
            ],
            [{
                title: "Édition Entreprise AI",
                illustrate1: "Montant de l'allocation requis : 30 000 USD à 50 000 USD",
                illustrate2: "Taux d'intérêt journalier : 0,90 %-0,95 %",
                illustrate3: "Taux d'intérêt mensuel : 27%-28,5%",
                illustrate4: "Signature : 30 jours",
                introduce: "Investissez 30 000 USD et obtenez un rendement d'environ 8 100 USD ou plus en 30 jours. Investissez 50 000 USD et obtenez un rendement d'environ 13 500 USD ou plus en 30 jours. Le montant de l'investissement peut être choisi en"
            }],
            [
                {
                    title: "Version personnalisée AI",
                    illustrate1: "Montant de l'allocation requis : 50 000 à 200 000",
                    illustrate2: "Taux d'intérêt quotidien : 0,01-0,012",
                    illustrate3: "Taux d'intérêt mensuel : 30 % à 36 %",
                    illustrate4: "Signature : 30 jours",
                    introduce: "Après avoir signé le contrat avec succès, vous devez allouer des fonds chaque jour pour obtenir le revenu. Investissez 50 000 USD. Le rendement du 30 sera d'environ 15 000 USD ou plus. Investissez 200 000 USD ou plus. plus Le montant de l'investissement peut être choisi entre 50 000 et 200 000."
                }
            ]
        ]
    },
    'es': {
        listdata: [
            [
                {
                    title: "Robot 1 estrella",
                    illustrate1: 'Asignación de fondos: 100USDT-300USDT',
                    illustrate2: 'Tasa de interés diaria: 1%',
                    illustrate4: 'Contrato: 10 días',
                    introduce: 'Robot 1 estrella  está abierto a nuevos usuarios con un período de contrato de 10 días, lo que les permite experimentar rápidamente el proceso de transacción y comprender mejor nuestros productos. Proceso operativo: una vez firmado el contrato, el robot asignará fondos en el sistema y realizará inversiones basadas en big data. Una vez realizada la inversión, el robot comenzará a operar automáticamente en el mercado. Hora de liquidación de fondos y ganancias: [Italia 00:00] Después de una liquidación exitosa, el usuario debe hacer clic en invertir nuevamente al día siguiente para continuar operando. Requisitos de apertura de cuenta: el saldo de la cuenta debe estar entre 100 USDT y 300 USDT.\nDerechos de usuario: distribuidor de primer nivel, 10% de comisión.'
                }
            ],
            [
                {
                    title: "Robot 2 estrella",
                    illustrate1: 'Asignación de fondos: 300USDT-1000USDT',
                    illustrate2: 'Tasa de interés diaria: 1.5%',
                    illustrate4: 'Contrato: 20 días',
                    introduce: 'Robot 2 estrella  está abierto a nuevos usuarios con un período de contrato de 20 días, lo que les permite experimentar rápidamente el proceso de transacción y comprender mejor nuestros productos. Proceso operativo: una vez firmado el contrato, el robot asignará fondos en el sistema y realizará inversiones basadas en big data. Una vez realizada la inversión, el robot comenzará a operar automáticamente en el mercado. Hora de liquidación de fondos y ganancias: [00:00 en Italia] Después de una liquidación exitosa, el usuario debe hacer clic en invertir nuevamente al día siguiente para continuar operando. Requisitos de apertura de cuenta: el saldo de la cuenta debe estar entre 300 USDT y 1000 USDT.\nDerechos de usuario: distribuidor de primer nivel, 10% de comisión.'
                }
            ],
            [
                {
                    title: "Robot 3 estrella",
                    illustrate1: 'Asignación de fondos: 100USDT-5000USDT',
                    illustrate2: 'Tasa de interés diaria: 1.5%',
                    illustrate4: 'Contrato: 30 días',
                    introduce: 'Robot 3 estrella  está abierto a nuevos usuarios con un período de contrato de 30 días, lo que les permite experimentar rápidamente el proceso de transacción y comprender mejor nuestros productos. Proceso operativo: una vez firmado el contrato, el robot asignará fondos en el sistema y realizará inversiones basadas en big data. Una vez realizada la inversión, el robot comenzará a operar automáticamente en el mercado. Hora de liquidación de fondos y ganancias: [00:00 en Italia] Después de una liquidación exitosa, el usuario debe hacer clic en invertir nuevamente al día siguiente para continuar operando. Requisitos de apertura de cuenta: el saldo de la cuenta debe estar entre 100 USDT y 5000 USDT.\nDerechos de usuario: distribuidor de primer nivel, 10% de comisión.'
                }
            ],
            [
                {
                    title: "Robot 4 estrella",
                    illustrate1: 'Asignación de fondos: 5000USDT-20000USDT',
                    illustrate2: 'Tasa de interés diaria: 2%',
                    illustrate4: 'Contrato: 30 días',
                    introduce: 'Robot 4 estrella  está abierto a nuevos usuarios con un período de contrato de 30 días, lo que les permite experimentar rápidamente el proceso de transacción y comprender mejor nuestros productos. Proceso operativo: una vez firmado el contrato, el robot asignará fondos en el sistema y realizará inversiones basadas en big data. Una vez realizada la inversión, el robot comenzará a operar automáticamente en el mercado. Hora de liquidación de fondos y ganancias: [00:00 en Italia] Después de una liquidación exitosa, el usuario debe hacer clic en invertir nuevamente al día siguiente para continuar operando. Requisitos de apertura de cuenta: el saldo de la cuenta debe estar entre 5000 USDT y2000 USDT.\nDerechos de usuario: distribuidor de primer nivel, 10% de comisión.'
                }


            ],
            [{
                title: "Robot 5 estrella",
                illustrate1: 'Asignación de fondos: 20000USDT-50000USDT',
                illustrate2: 'Tasa de interés diaria: 2%',
                illustrate4: 'Contrato: 30 días',
                introduce: 'Robot 5 estrella  está abierto a nuevos usuarios con un período de contrato de 30 días, lo que les permite experimentar rápidamente el proceso de transacción y comprender mejor nuestros productos. Proceso operativo: una vez firmado el contrato, el robot asignará fondos en el sistema y realizará inversiones basadas en big data. Una vez realizada la inversión, el robot comenzará a operar automáticamente en el mercado. Hora de liquidación de fondos y ganancias: [00:00 en Italia] Después de una liquidación exitosa, el usuario debe hacer clic en invertir nuevamente al día siguiente para continuar operando. Requisitos de apertura de cuenta: el saldo de la cuenta debe estar entre 20000 USDT y50000 USDT.\nDerechos de usuario: distribuidor de primer nivel, 10% de comisión.'
            }
            ],
            [{
                title: "SGC VIP Robot",
                illustrate1: 'Asignación de fondos: 50000USDT-5000000USDT',
                illustrate2: 'Tasa de interés diaria: 3%',
                illustrate4: 'Contrato: 30 días',
                introduce: 'SGC VIP Robot está abierto a nuevos usuarios con un período de contrato de 30 días, lo que les permite experimentar rápidamente el proceso de transacción y comprender mejor nuestros productos. Proceso operativo: una vez firmado el contrato, el robot asignará fondos en el sistema y realizará inversiones basadas en big data. Una vez realizada la inversión, el robot comenzará a operar automáticamente en el mercado. Hora de liquidación de fondos y ganancias: [00:00 en Italia] Después de una liquidación exitosa, el usuario debe hacer clic en invertir nuevamente al día siguiente para continuar operando. Requisitos de apertura de cuenta: el saldo de la cuenta debe estar entre 50000 USDT y5000000 USDT.\nDerechos de usuario: distribuidor de primer nivel, 10% de comisión.'
            }]
        ]
    },
    'ita': {
        listdata: [
            [
                {
                    title: "Versione base AI",
                    illustrate1: 'Importo di allocazione richiesto: US$100-US$300',
                    illustrate2: 'Tasso di interesse giornaliero: 0,44%-0,45%',
                    illustrate3: 'Tasso di interesse mensile: circa 13,2%-13,5%',
                    illustrate4: 'Firma: 15',
                    introduce: "[Versione base di AI] è aperta ai principianti quando la utilizzano per la prima volta. Possono sperimentare l'effetto del tasso di rendimento e la stabilità apportati dai prodotti dell'azienda. Dopo aver firmato con successo, è necessario stanziare fondi ogni giorno per ottenere entrate Investi 100 USD e il reddito verrà restituito il 15. Circa 7 USD o più. Investimento di 300 USD, reddito di ritorno in 15 giorni pari a circa 21 USD o più. L'importo dell'investimento può essere scelto tra 100 e 300 numeri."
                }
            ],
            [
                {
                    title: "Edizione iniziale AI",
                    illustrate1: 'Importo di allocazione richiesto: 500USD-1000USD',
                    illustrate2: 'Tasso di interesse giornaliero: 0,48%-0,50%',
                    illustrate3: 'Tasso di interesse mensile: 14,4%-15%',
                    illustrate4: 'Firma: 30 giorni',
                    introduce: "Dopo aver firmato con successo il contratto, devi stanziare fondi ogni giorno per ottenere il reddito. Investi 500 USD. Il rendimento in 30 giorni sarà di circa 72 USD o più l'importo può essere scelto tra 500 e 1000."
                }
            ],
            [
                {
                    title: "Edizione standard AI",
                    illustrate1: 'Importo di allocazione richiesto: 1000 USD-3000 USD',
                    illustrate2: 'Tasso di interesse giornaliero: 0,60%-0,65%',
                    illustrate3: 'Tasso di interesse mensile: 18%-19,5%',
                    illustrate4: 'Firma: 30 giorni',
                    introduce: "Dopo aver firmato con successo il contratto, devi stanziare fondi ogni giorno per ottenere il reddito. Investi 1.000 USD. Il reddito di ritorno al 30° giorno è di circa 180 USD o più più L'importo dell'investimento può essere scelto tra 1.000 e 3.000 numeri. Il rendimento giornaliero è di circa 144 USD o più. L'importo dell'investimento può essere scelto tra 500 e 1000 numeri."
                }
            ],
            [
                {
                    title: "Versione premium AI",
                    illustrate1: 'Importo di allocazione richiesto: 3000 USD-10000 USD',
                    illustrate2: 'Tasso di interesse giornaliero: 0,70%-0,75%',
                    illustrate3: 'Tasso di interesse mensile: 21%-22,5%',
                    illustrate4: 'Firma: 30 giorni',
                    introduce: "Importo di allocazione richiesto: 3000 USD-10000 USD Tasso di interesse giornaliero: 0,70%-0,75% Tasso di interesse mensile: 21%-22,5% Contratto: dopo aver firmato con successo il 30, è necessaria un'allocazione giornaliera per ottenere un reddito e un reddito di ritorno il 30 sarà di circa 630 USD oppure Se investi più di 10.000 USD, otterrai rendimenti in 30 giorni. L'importo dell'investimento è di circa 2.100 USD o più. Puoi scegliere qualsiasi numero compreso tra 3.000 e 10.000."
                }


            ],
            [{
                title: "Versione professionale AI",
                illustrate1: 'Importo di allocazione richiesto: 10.000 USD-30.000 USD',
                illustrate2: 'Tasso di interesse giornaliero: 0,80%-0,85%',
                illustrate3: 'Tasso di interesse mensile: 24%-25,5%',
                illustrate4: 'Firma: 30 giorni',
                introduce: "Dopo aver firmato con successo il contratto, devi stanziare fondi ogni giorno per ottenere il reddito. Investi 10.000 USD. Il rendimento il 30 sarà di circa 2.400 USD o più. o più L'importo dell'investimento può essere scelto tra 10.000 e 30.000."
            }
            ],
            [{
                title: "Edizione aziendale AI",
                illustrate1: 'Importo di allocazione richiesto: 30.000 USD-50.000 USD',
                illustrate2: 'Tasso di interesse giornaliero: 0,90%-0,95%',
                illustrate3: 'Tasso di interesse mensile: 27%-28,5%',
                illustrate4: 'Firma: 30 giorni',
                introduce: "Investi 30.000 USD e ottieni un rendimento di circa 8.100 USD o più in 30 giorni Investi 50.000 USD e ottieni un rendimento di circa 13.500 USD o più in 30 giorni L'importo dell'investimento può essere scelto tra 30.000 e 50.000."
            }],
            [
                {
                    title: "Versione personalizzata AI",
                    illustrate1: 'Importo della dotazione richiesta: 50.000-200.000',
                    illustrate2: 'Tasso di interesse giornaliero: 0,01-0,012',
                    illustrate3: 'Tasso di interesse mensile: 30%-36%',
                    illustrate4: 'Firma: 30 giorni',
                    introduce: "Dopo aver firmato con successo il contratto, devi stanziare fondi ogni giorno per ottenere il reddito. Investi 50.000 USD. Il rendimento il 30 sarà di circa 15.000 USD o più in più L'importo dell'investimento può essere scelto tra 50.000 e 200.000."
                }
            ]
        ]
    },
    'pt': {
        listdata: [
            [
                {
                    title: "Versão básica AI",
                    illustrate1: 'Valor de alocação necessário: US$ 100-US$ 300',
                    illustrate2: 'Taxa de juros diária: 0,44% -0,45%',
                    illustrate3: 'Taxa de juros mensal: cerca de 13,2% -13,5%',
                    illustrate4: 'Assinatura: dia 15',
                    introduce: '[Versão básica da IA] está aberta para iniciantes experimentarem ao usá-la pela primeira vez. Eles podem experimentar o efeito da taxa de retorno e a estabilidade trazida pelos produtos da empresa. Após a assinatura bem-sucedida, você precisa alocar fundos todos os dias para obter renda. .Invista 100USD, e o rendimento será devolvido no dia 15. Cerca de 7USD ou mais de investimento de 300USD, o rendimento de retorno de 15 dias é de cerca de 21USD ou mais.'
                }
            ],
            [
                {
                    title: "Versão júnior AI",
                    illustrate1: 'Valor de alocação necessário: 500USD-1000USD',
                    illustrate2: 'Taxa de juros diária: 0,48% -0,50%',
                    illustrate3: 'Taxa de juros mensal: 14,4% -15%',
                    illustrate4: 'Assinatura: 30 dias',
                    introduce: 'Depois de assinar o contrato com sucesso, você precisa alocar fundos todos os dias para obter a renda. O retorno em 30 dias será de cerca de 72USD ou mais. o valor pode ser escolhido entre 500 e 1000.'
                }
            ],
            [
                {
                    title: "Edição Padrão AI",
                    illustrate1: 'Valor de alocação necessário: 1000USD-3000usd',
                    illustrate2: 'Taxa de juros diária: 0,60% -0,65%',
                    illustrate3: 'Taxa de juros mensal: 18% -19,5%',
                    illustrate4: 'Assinatura: 30 dias',
                    introduce: 'Depois de assinar o contrato com sucesso, você precisa alocar fundos todos os dias para obter a receita. Investir 1.000USD. A receita de retorno no 30º dia é de cerca de 180USD ou mais. mais. O valor do investimento pode ser escolhido entre 1.000 e 3.000 números 30 O rendimento de retorno diário é de cerca de 144USD ou mais.'
                }
            ],
            [
                {
                    title: "Versão premium AI",
                    illustrate1: 'Valor de alocação necessário: 3.000USD-10.000USD',
                    illustrate2: 'Taxa de juros diária: 0,70% -0,75%',
                    illustrate3: 'Taxa de juros mensal: 21% -22,5%',
                    illustrate4: 'Assinatura: 30 dias',
                    introduce: 'Valor de alocação necessário: 3.000USD-10.000USD Taxa de juros diária: 0,70%-0,75% Taxa de juros mensal: 21%-22,5% Contrato: Após assinatura bem-sucedida no dia 30, a alocação diária é necessária para obter renda Investir 3.000USD, e o rendimento de retorno no dia 30. o dia 30 será de aproximadamente 630USD ou se você investir mais de 10.000USD, obterá retornos em 30 dias. O valor do investimento é de cerca de 2.100USD ou mais.'
                }


            ],
            [{
                title: "Versão profissional AI",
                illustrate1: 'Valor de alocação necessário: 10.000USD-30.000USD',
                illustrate2: 'Taxa de juros diária: 0,80% -0,85%',
                illustrate3: 'Taxa de juros mensal: 24% -25,5%',
                illustrate4: 'Assinatura: 30 dias',
                introduce: 'Depois de assinar o contrato com sucesso, você precisa alocar fundos todos os dias para obter a renda. Investir 10.000USD. O retorno no dia 30 será de cerca de 2.400USD ou mais. ou mais. O valor do investimento pode ser escolhido entre 10.000 e 30.000.'
            }
            ],
            [{
                title: "Edição Empresarial AI",
                illustrate1: 'Valor de alocação necessário: 30.000USD-50.000USD',
                illustrate2: 'Taxa de juros diária: 0,90% -0,95%',
                illustrate3: 'Taxa de juros mensal: 27% -28,5%',
                illustrate4: 'Assinatura: 30 dias',
                introduce: 'Invista 30.000USD e obtenha um retorno de cerca de 8.100USD ou mais em 30 dias. Invista 50.000USD e obtenha um retorno de cerca de 13.500USD ou mais em 30 dias.'
            }],
            [
                {
                    title: "Versão personalizada AI",
                    illustrate1: 'Valor de alocação necessário: 50.000-200.000',
                    illustrate2: 'Taxa de juros diária: 0,01-0,012',
                    illustrate3: 'Taxa de juros mensal: 30% -36%',
                    illustrate4: 'Assinatura: 30 dias',
                    introduce: 'Depois de assinar o contrato com sucesso, você precisará alocar fundos todos os dias para obter a renda. Investir 50.000USD. O retorno no dia 30 será de cerca de 15.000USD ou mais. mais. O valor do investimento pode ser escolhido entre 50.000 e 200.000.'
                }
            ]
        ]
    },
    'nl': {
        listdata: [
            [
                {
                    title: "Basisversie AI",
                    illustrate1: 'Vereist toewijzingsbedrag: US$100-US$300',
                    illustrate2: 'Dagelijkse rente: 0,44%-0,45%',
                    illustrate3: 'Maandelijkse rente: ongeveer 13,2%-13,5%',
                    illustrate4: 'Ondertekening: 15e',
                    introduce: '[Basisversie van AI] staat open voor beginners om te ervaren wanneer ze het voor de eerste keer gebruiken. Ze kunnen het rendementseffect en de stabiliteit van de producten van het bedrijf ervaren. Na succesvolle ondertekening moet u elke dag geld toewijzen om inkomsten te krijgen Investeer 100 USD en het inkomen wordt op de 15e terugbetaald. Een investering van ongeveer 7 USD of meer van 300 USD, een rendement over 15 dagen is ongeveer 21 USD of meer.'
                }
            ],
            [
                {
                    title: "Starter editie AI",
                    illustrate1: 'Vereist toewijzingsbedrag: 500USD-1000USD',
                    illustrate2: 'Dagelijkse rente: 0,48%-0,50%',
                    illustrate3: 'Maandelijkse rente: 14,4%-15%',
                    illustrate4: 'Ondertekening: 30 dagen',
                    introduce: 'Nadat u het contract met succes heeft ondertekend, moet u elke dag geld toewijzen om de inkomsten te verkrijgen. Het rendement op 30 dagen zal ongeveer 72 USD of meer zijn bedrag kan worden gekozen tussen 500 en 1000.'
                }
            ],
            [
                {
                    title: "Standaard editie AI",
                    illustrate1: 'Vereist toewijzingsbedrag: 1000USD-3000usd',
                    illustrate2: 'Dagelijkse rente: 0,60%-0,65%',
                    illustrate3: 'Maandelijkse rente: 18%-19,5%',
                    illustrate4: 'Ondertekening: 30 dagen',
                    introduce: 'Nadat u het contract met succes heeft ondertekend, moet u elke dag geld toewijzen om de inkomsten te ontvangen. Het rendement op de 30e dag bedraagt ​​ongeveer 180 USD of meer meer. Het investeringsbedrag kan worden gekozen tussen 1.000 en 3.000 nummers. Het dagelijkse rendement bedraagt ​​ongeveer 144USD of meer. Het investeringsbedrag kan worden gekozen tussen 500 en 1000 nummers.'
                }
            ],
            [
                {
                    title: "Premium-versie AI",
                    illustrate1: 'Vereist toewijzingsbedrag: 3000USD-10000USD',
                    illustrate2: 'Dagelijkse rente: 0,70%-0,75%',
                    illustrate3: 'Maandelijkse rente: 21%-22,5%',
                    illustrate4: 'Ondertekening: 30 dagen',
                    introduce: 'Vereist toewijzingsbedrag: 3000USD-10000USD Dagelijkse rente: 0,70%-0,75% Maandelijkse rente: 21%-22,5% Contract: Na succesvolle ondertekening op de 30e is een dagelijkse toewijzing vereist om inkomsten uit Invest 3000 te verkrijgen de 30e bedraagt ​​ongeveer 630 USD. Als u meer dan 10.000 USD investeert, krijgt u binnen 30 dagen rendement. Het investeringsbedrag bedraagt ​​ongeveer 2.100 USD of meer. U kunt een getal tussen 3.000 en 10.000 kiezen.'
                }


            ],
            [{
                title: "Professionele versie AI",
                illustrate1: 'Vereist toewijzingsbedrag: 10.000USD-30.000USD',
                illustrate2: 'Dagelijkse rente: 0,80%-0,85%',
                illustrate3: 'Maandelijkse rente: 24%-25,5%',
                illustrate4: 'Ondertekening: 30 dagen',
                introduce: 'Nadat u het contract succesvol heeft ondertekend, moet u elke dag geld toewijzen om de inkomsten te verkrijgen. Het rendement op de 30e zal ongeveer 2.400 USD of meer zijn. Investeer 30.000 USD, en het rendement op de 30e zal ongeveer 7.200 USD zijn. of meer Het investeringsbedrag kan worden gekozen tussen 10.000 en 30.000.'
            }
            ],
            [{
                title: "Enterprise-editie AI",
                illustrate1: 'Vereist toewijzingsbedrag: 30.000USD-50.000USD',
                illustrate2: 'Dagelijkse rente: 0,90%-0,95%',
                illustrate3: 'Maandelijkse rente: 27%-28,5%',
                illustrate4: 'Ondertekening: 30 dagen',
                introduce: 'Investeer 30.000 USD en ontvang een rendement van ongeveer 8.100 USD of meer in 30 dagen. Investeer 50.000 USD en ontvang een rendement van ongeveer 13.500 USD of meer in 30 dagen.'
            }],
            [
                {
                    title: "Aangepaste versie AI",
                    illustrate1: 'Vereist toewijzingsbedrag: 50.000-200.000',
                    illustrate2: 'Dagelijkse rente: 0,01-0,012',
                    illustrate3: 'Maandelijkse rente: 30%-36%',
                    illustrate4: 'Ondertekening: 30 dagen',
                    introduce: 'Nadat u het contract met succes heeft ondertekend, moet u elke dag geld toewijzen om de inkomsten te verkrijgen. Het rendement op de 30e zal ongeveer 15.000 USD zijn of meer meer Het investeringsbedrag kan worden gekozen tussen 50.000 en 200.000.'
                }
            ]
        ]
    },
    'el': {
        listdata: [
            [
                {
                    title: "Βασική έκδοση AI",
                    illustrate1: 'Απαιτούμενο ποσό κατανομής: 100$-300$',
                    illustrate2: 'Ημερήσιο επιτόκιο: 0,44%-0,45%',
                    illustrate3: 'Μηνιαίο επιτόκιο: περίπου 13,2%-13,5%',
                    illustrate4: 'Υπογραφή: 15η',
                    introduce: 'Η [Βασική έκδοση του AI] είναι ανοιχτή για τους αρχάριους όταν το χρησιμοποιούν για πρώτη φορά. Μπορούν να βιώσουν το ποσοστό απόδοσης και τη σταθερότητα που προσφέρουν τα προϊόντα της εταιρείας Επενδύστε 100 USD και το εισόδημα θα επιστραφεί στις 15. Περίπου 300 USD ή περισσότερο, το εισόδημα επιστροφής 15 ημερών είναι περίπου 21 USD ή περισσότερο'
                }
            ],
            [
                {
                    title: "Junior έκδοση AI",
                    illustrate1: 'Απαιτούμενο ποσό κατανομής: 500 USD-1000 USD',
                    illustrate2: 'Ημερήσιο επιτόκιο: 0,48%-0,50%',
                    illustrate3: 'Μηνιαίο επιτόκιο: 14,4%-15%',
                    illustrate4: 'Υπογραφή: 30 ημέρες',
                    introduce: 'Μετά την επιτυχή υπογραφή της σύμβασης, πρέπει να διαθέτετε κεφάλαια κάθε μέρα για να λάβετε το εισόδημα σε 30 USD ή περισσότερο Το ποσό μπορεί να επιλεγεί μεταξύ 500 και 1000.'
                }
            ],
            [
                {
                    title: "Τυπική Έκδοση AI",
                    illustrate1: 'Απαιτούμενο ποσό κατανομής: 1000 USD-3000 USD',
                    illustrate2: 'Ημερήσιο επιτόκιο: 0,60%-0,65%',
                    illustrate3: 'Μηνιαίο επιτόκιο: 18%-19,5%',
                    illustrate4: 'Υπογραφή: 30 ημέρες',
                    introduce: 'Μετά την επιτυχή υπογραφή της σύμβασης, πρέπει να διαθέτετε χρήματα κάθε μέρα για να λάβετε το εισόδημα 1.000 USD περισσότερα Το ποσό της επένδυσης μπορεί να επιλεγεί μεταξύ 1.000 και 3.000 αριθμώ'
                }
            ],
            [
                {
                    title: "Έκδοση Premium AI",
                    illustrate1: 'Απαιτούμενο ποσό κατανομής: 3000USD-10000USD',
                    illustrate2: 'Ημερήσιο επιτόκιο: 0,70%-0,75%',
                    illustrate3: 'Μηνιαίο επιτόκιο: 21%-22,5%',
                    illustrate4: 'Υπογραφή: 30 ημέρες',
                    introduce: 'Απαιτούμενο ποσό κατανομής: 3000USD-10000USD Ημερήσιο επιτόκιο: 0,70%-0,75% Μηνιαίο επιτόκιο: 21%-22,5% Σύμβαση: Μετά την επιτυχή υπογραφή στις 30, απαιτείται ημερήσια κατανομή για την απόκτηση εισοδήματος Επένδυση 3000 USD και το εισόδημα επιστροφής Το 30ό ποσό θα είναι περίπου 630 USD ή εάν επενδύσετε περισσότερα από 10.000 USD, θα λάβετε επιστροφές σε 30 ημέρες. Το ποσό της επένδυσης είναι περίπου 2.100 USD ή περισσότερο.'
                }


            ],
            [{
                title: "Επαγγελματική έκδοση AI",
                illustrate1: 'Απαιτούμενο ποσό κατανομής: 10.000 USD-30.000 USD',
                illustrate2: 'Ημερήσιο επιτόκιο: 0,80%-0,85%',
                illustrate3: 'Μηνιαίο επιτόκιο: 24%-25,5%',
                illustrate4: 'Υπογραφή: 30 ημέρες',
                introduce: 'Μετά την επιτυχή υπογραφή της σύμβασης, πρέπει να διαθέτετε χρήματα κάθε μέρα για να λάβετε το εισόδημα 10.000 USD. ή περισσότερο Το ποσό της επένδυσης μπορεί να επιλεγεί μεταξύ 10.000 και 30.000.'
            }
            ],
            [{
                title: "Enterprise Edition AI",
                illustrate1: 'Απαιτούμενο ποσό κατανομής: 30.000 USD-50.000 USD',
                illustrate2: 'Ημερήσιο επιτόκιο: 0,90%-0,95%',
                illustrate3: 'Μηνιαίο επιτόκιο: 27%-28,5%',
                illustrate4: 'Υπογραφή: 30 ημέρες',
                introduce: 'Επενδύστε 30.000 USD και λάβετε απόδοση περίπου 8.100 USD σε 30 ημέρες και λάβετε απόδοση περίπου 13.500 USD ή περισσότερο σε 30 ημέρες.'
            }],
            [
                {
                    title: "Προσαρμοσμένη έκδοση AI",
                    illustrate1: 'Απαιτούμενο ποσό διάθεσης: 50.000-200.000',
                    illustrate2: 'Ημερήσιο επιτόκιο: 0,01-0,012',
                    illustrate3: 'Μηνιαίο επιτόκιο: 30%-36%',
                    illustrate4: 'Υπογραφή: 30 ημέρες',
                    introduce: 'Μετά την επιτυχή υπογραφή της σύμβασης, πρέπει να διαθέτετε χρήματα κάθε μέρα για να λάβετε το εισόδημα στις 30.000 USD ή περισσότερο περισσότερα Το ποσό της επένδυσης μπορεί να επιλεγεί μεταξύ 50.000 και 200.000.'
                }
            ]
        ]
    },
    'pl': {
        listdata: [
            [
                {
                    title: "Wersja podstawowa AI",
                    illustrate1: 'Wymagana kwota alokacji: 100-300 USD',
                    illustrate2: 'Dzienna stopa procentowa: 0,44% -0,45%',
                    illustrate3: 'Miesięczne oprocentowanie: ok. 13,2%-13,5%',
                    illustrate4: 'Podpisanie: 15',
                    introduce: '[Podstawowa wersja AI] jest otwarta dla nowicjuszy, którzy korzystają z niej po raz pierwszy, mogą doświadczyć stopy zwrotu i stabilności, jaką zapewniają produkty firmy. Po pomyślnym podpisaniu trzeba codziennie przeznaczyć środki, aby uzyskać dochód Zainwestuj 100 USD, a dochód zostanie zwrócony 15-go. Około 7 USD lub więcej przy inwestycji o wartości 300 USD, 15-dniowy dochód z inwestycji wynosi około 21 USD lub więcej. Kwotę inwestycji można wybrać z zakresu od 100 do 300.'
                }
            ],
            [
                {
                    title: "Wersja startowa AI",
                    illustrate1: 'Wymagana kwota alokacji: 500USD-1000USD',
                    illustrate2: 'Dzienna stopa procentowa: 0,48% -0,50%',
                    illustrate3: 'Miesięczne oprocentowanie: 14,4%-15%',
                    illustrate4: 'Podpisanie: 30 dni',
                    introduce: 'Po pomyślnym podpisaniu umowy musisz codziennie przeznaczyć środki, aby uzyskać dochód w wysokości 500 USD. Zwrot w ciągu 30 dni wyniesie około 72 USD lub więcej. Zwrot w przypadku inwestycji w ciągu 30 dni wyniesie około 144 USD lub więcej kwotę można wybrać z zakresu od 500 do 1000.'
                }
            ],
            [
                {
                    title: "Wersja standardowa AI",
                    illustrate1: 'Wymagana kwota alokacji: 1000USD-3000usd',
                    illustrate2: 'Dzienna stopa procentowa: 0,60% -0,65%',
                    illustrate3: 'Miesięczne oprocentowanie: 18%-19,5%',
                    illustrate4: 'Podpisanie: 30 dni',
                    introduce: 'Po pomyślnym podpisaniu umowy musisz codziennie przeznaczyć środki, aby uzyskać dochód w wysokości 1000 USD. Dochód z zwrotu w 30. dniu wynosi około 180 USD lub więcej. Zainwestuj 3000 USD w przypadku zwrotu w 30. dniu więcej. Kwotę inwestycji można wybrać od 1000 do 3000 liczb. 30 Dzienny dochód ze zwrotu wynosi około 144 USD lub więcej. Kwotę inwestycji można wybrać od 500 do 1000 liczb.'
                }
            ],
            [
                {
                    title: "Wersja premium AI",
                    illustrate1: 'Wymagana kwota alokacji: 3000USD-10000USD',
                    illustrate2: 'Dzienna stopa procentowa: 0,70% -0,75%',
                    illustrate3: 'Miesięczne oprocentowanie: 21%-22,5%',
                    illustrate4: 'Podpisanie: 30 dni',
                    introduce: 'Wymagana kwota alokacji: 3000USD-10000USD Dzienna stopa procentowa: 0,70%-0,75% Miesięczna stopa procentowa: 21%-22,5% Kontrakt: Po pomyślnym podpisaniu 30-go dnia wymagana jest dzienna alokacja, aby uzyskać dochód 3000USD i zwrot dochodu 30-tego wyniesie około 630 USD lub Jeśli zainwestujesz więcej niż 10 000 USD, otrzymasz zwrot w ciągu 30 dni. Kwota inwestycji wynosi około 2100 USD lub więcej. Możesz wybrać dowolną liczbę od 3 000 do 10 000.'
                }


            ],
            [{
                title: "Wersja profesjonalna AI",
                illustrate1: 'Wymagana kwota alokacji: 10 000 USD-30 000 USD',
                illustrate2: 'Dzienna stopa procentowa: 0,80% -0,85%',
                illustrate3: 'Miesięczne oprocentowanie: 24%-25,5%',
                illustrate4: 'Podpisanie: 30 dni',
                introduce: 'Po pomyślnym podpisaniu umowy musisz codziennie przeznaczyć środki, aby uzyskać dochód 30 000 USD. Zainwestuj 30 000 USD, a zwrot 30-go wyniesie około 7 200 USD. lub więcej Kwota inwestycji może wynosić od 10 000 do 30 000.'
            }
            ],
            [{
                title: "Wersja korporacyjna",
                illustrate1: 'Wymagana kwota alokacji: 30 000 USD-50 000 USD',
                illustrate2: 'Dzienna stopa procentowa: 0,90% -0,95%',
                illustrate3: 'Miesięczne oprocentowanie: 27%-28,5%',
                illustrate4: 'Podpisanie: 30 dni',
                introduce: 'Zainwestuj 30 000 USD i uzyskaj zwrot w wysokości około 8 100 USD lub więcej w ciągu 30 dni Zainwestuj 50 000 USD i uzyskaj zwrot w wysokości około 13 500 USD lub więcej w ciągu 30 dni. Kwota inwestycji może wynosić od 30 000 do 50 000.'
            }],
            [
                {
                    title: "Wersja dostosowana AI",
                    illustrate1: 'Wymagana kwota alokacji: 50 000-200 000',
                    illustrate2: 'Dzienna stopa procentowa: 0,01-0,012',
                    illustrate3: 'Miesięczne oprocentowanie: 30% -36%',
                    illustrate4: 'Podpisanie: 30 dni',
                    introduce: 'Po pomyślnym podpisaniu umowy musisz codziennie przeznaczyć środki, aby uzyskać dochód 30 000 USD lub więcej. Zainwestuj 200 000 USD. Zwrot 30-tego wyniesie około 60 000 USD więcej Kwota inwestycji może wynosić od 50 000 do 200 000.'
                }
            ]
        ]
    },
}

export default packListData