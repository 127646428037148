export default {
    navList: {
        nav1: 'Αρχική',
        nav2: 'Πλεονεκτήματα',
        nav3: 'Χαρτοφυλάκιο',
        nav4: 'Βίντεο AI',
        nav5: 'Επικοινωνία',
        login: 'Σύνδεση',
        register: 'Εγγραφή'
    },
    Language: "Γλώσσα",
    down: 'Λήψη',
    Menu: 'Μενού',
    bannerTitle: '【SGC AI】Ποσοτικοποίηση Συναλλαγών',
    bannerSubtitle: 'Σταθερή Απόδοση',
    bannerListTxt1: 'Επανεξισορρόπηση',
    bannerListTxt2: 'Διαχείριση Χαρτοφυλακίου',
    bannerListTxt3: 'Συλλογή Δεδομένων',
    bannerListTxt4: 'Έξυπνη Δρομολόγηση Παραγγελιών',
    bannerDownBtn: 'Λήψη για Android',
    bannerSubscribeBtn: 'Εγγραφείτε Τώρα',
    ProductAdvantages: 'Πλεονεκτήματα vs Ανθρώπινα',
    advantagesList: {
        title1: 'Η διαφορά μεταξύ πλεονεκτημάτων προϊόντος και εργασίας',
        describe1: 'Τα πλεονεκτήματα σχεδιασμού των μοντέλων ποσοτικής στρατηγικής AI αποτελείται από περισσότερους από εκατοντάδες παράγοντες που μπορούν να επηρεάσουν την άνοδο και την πτώση των μετοχών. Είναι δύσκολο για χειροκίνητη ανάλυση και έρευνα να ικανοποιήσει τέτοιες απαιτήσεις.',
        title2: 'Πλεονεκτήματα της λειτουργίας δοκιμής επιστροφής ιστορικών δεδομένων',
        describe2: 'Για να σχεδιάσετε ένα ποσοτικό μοντέλο AI με ποσοστό απόδοσης που υπερβαίνει κατά πολύ το ποσοστό απόδοσης της αγοράς, μπορείτε να λάβετε το ιστορικό ετήσιο ποσοστό απόδοσης του μοντέλου ελέγχοντας τα δεδομένα ιστορικού κύκλου. Από αυτά μπορεί να επιλεγεί το ιδανικότερο μοντέλο. Αυτό είναι απλά αδύνατο να γίνει με το χέρι, ανεξάρτητα από το πόσο χρόνο χρειάζεται. Επομένως, οι χειρωνακτικές λειτουργίες συχνά δεν είναι αυστηρές και αντιεπιστημονικές και βασίζονται μόνο σε προσωπικά συναισθήματα και εμπειρία για την επιλογή στόχων για λειτουργία.',
        title3: 'Πλεονεκτήματα της αυτόματης σάρωσης περισσότερων από 400.000 μετοχών στην παγκόσμια αγορά',
        describe3: 'Το ρομπότ AI σαρώνει αυτόματα περισσότερες από 400.000 μετοχές σε ολόκληρη την αγορά κατά τη διάρκεια της ημέρας σύμφωνα με τις συνθήκες και άλλες καθορισμένες παραμέτρους που έχουν οριστεί από τεχνικούς ποσοτικών μοντέλων και ολοκληρώνει τη λειτουργία αμέσως όταν διαπιστώσει ότι πληρούνται οι καθορισμένοι στόχοι. Χρειάζονται μόνο λίγα δευτερόλεπτα. Η χειροκίνητη ανάλυση θα διαρκούσε χρόνια ή και δεκαετίες και δεν θα μπορούσε να ολοκληρώσει τον ίδιο όγκο εργασίας.',
        title4: 'Μόνο με την κατανόηση της ιστορίας μπορούμε να κρίνουμε το μέλλον με μεγαλύτερη ακρίβεια',
        describe4: 'Το ποσοστό απόδοσης ιστορικού ελέγχου δεδομένων του ποσοτικού μοντέλου μπορεί να χρησιμοποιηθεί ως καλύτερη αναφορά για την κρίση των μελλοντικών ποσοστών απόδοσης. της μετοχής στο σημείο αγοράς. Για να ικανοποιηθούν αυτές οι απαιτήσεις, η χειροκίνητη λειτουργία είναι απλώς αδύνατη.',
        title5: 'Καθιερώστε ένα μακροπρόθεσμο και σταθερό μοντέλο κέρδους',
        describe5: 'Για να αποκτήσουν μακροπρόθεσμα σταθερά κέρδη και τεράστιο σύνθετο επιτόκιο, οι τεχνικοί μηχανικοί μπορούν να προσαρμόσουν και να προσθέσουν τις δικές τους επιλεγμένες παραμέτρους δεικτών, να επαληθεύσουν το ποσοστό απόδοσης μέσω backtesting, να επιλέξουν την ιδανικότερη στρατηγική ή συνδυασμό και στη συνέχεια να πραγματοποιήσουν πλήρως αυτόματες συναλλαγές με ρομπότ AI. Αυτό το είδος εργασίας δεν μπορεί να γίνει με το χέρι.',
        title6: 'Ικανότητα λειτουργικής εκτέλεσης',
        describe6: 'Το πλεονέκτημα της πλήρως αυτόματης ποσοτικής διαπραγμάτευσης SGC AI είναι ότι χρησιμοποιεί ορθολογικά εργαλεία για τη λειτουργία των συναλλαγών σύμφωνα με τις ρυθμίσεις του μοντέλου. Οι χειρωνακτικές λειτουργίες συχνά επηρεάζονται από την άνοδο και την πτώση της αγοράς και από διάφορους άλλους παράγοντες και δεν μπορούν να λειτουργήσουν σύμφωνα με το καλύτερο μοντέλο κέρδους για μεγάλο χρονικό διάστημα.',
        title7: 'Απελευθερώστε πλήρως την παραγωγικότητα',
        describe7: 'Το ποσοτικό ρομπότ SGC AI μπορεί να ρυθμιστεί μία φορά και να εκτελεί αυτόματα λειτουργίες σύμφωνα με τις ρυθμίσεις για μεγάλο χρονικό διάστημα. Χρειάζεται μόνο να εκτελεί τη διαδικασία κατανομής κεφαλαίου μία φορά την ημέρα, η οποία μπορεί να απελευθερώσει πλήρως τους χρήστες από την επίπονη εργασία της διαπραγμάτευσης μετοχών. Ωστόσο, η μη αυτόματη ανάλυση και έρευνα, η παρακολούθηση της αγοράς και οι μη αυτόματες εμπιστευτικές συναλλαγές απαιτούν πολύ χρόνο και ενέργεια από τους επενδυτές.',
    },
    investmentPackage: "Επιλέξτε το Χαρτοφυλάκιό σας",
    versionTab1: 'Βασικό',
    versionTab2: 'Αρχάριος',
    versionTab3: 'Τυπικό',
    versionTab4: 'Προηγμένο',
    versionTab5: 'Επαγγελματικό',
    versionTab6: 'Εταιρικό',
    versionTab7: 'Προσαρμοσμένο',
    BusinessDocking: 'Επαφή Επιχειρήσεων',
    email: "Ηλεκτρονικό Ταχυδρομείο",
    officialEmail: 'Επίσημο Ηλεκτρονικό Ταχυδρομείο',
    privacyPolicy: 'Πολιτική Απορρήτου',
    TermOfService: "Όροι Χρήσης",
    Copyright: 'Πνευματικά Δικαιώματα'
}