<template>
	<div class="page">
		<HeaderView @scrollTo="scrollTo"></HeaderView>

		<div class="container-fluid cst_sectionBlock cst_BGtransparent c-section cbsect_50ef0f73" ref="bannerWrap">
			<div class="cst_sectionBGwrap c-div cbdiv_3bc0447f">
				<div class="cst_sectionBG jarallax cst_BGfixed c-div cbdiv_451ec72c" style="">
					<div class="cst_overlay c-div cbdiv_2b35fe11"></div>
				</div>
			</div>
			<div class="container cst_container_base c-container-1 cbcont_0ede67c7">
				<div
					class="cst_flexLayout_nn1 cst_flexLayout_n2n cst_mLR-15 cst_image_textbox_flex cst_flexReverse c-div cs-repeatable cbdiv_1c3ec868">
					<div class="cst_flexitem cst_setting_item c-div cbdiv_af1d3ca7">
						<div class="cst_divimage cst_leftTriangle c-div cbdiv_02510db5">
							<div class="cst_BGimageSetting cst_BGcontain c-inlineblock c-imageblock cbimag_d8a4548f">
							</div>

							<div class="hidden-xs cst_Triangleselect cst_Triangle c-div cbdiv_029ab91f">
								<svg class="cbsvg_099d3619" preserveAspectRatio="none" viewBox="0 0 50 50"
									xmlns="http://www.w3.org/2000/svg">
									<polygon fill="currentColor" points="50,0 50,50 0,50"></polygon>
								</svg>
							</div>
						</div>
					</div>
					<div class="cst_flexitem cst_flexsetting c-div cbdiv_ef56d505">
						<div class="cst_textSetting cst_textWrap c-div cbdiv_0073ec05">
							<div class="c-div cbdiv_2ab75839">
								<!-- <h1
									class="cst_title cst_title_underline c-heading youziku-c5a8f46018ec4cdfa827ba6989ffa10e cbhead_bfabd956">
									{{ $t('bannerTitle') }}
								</h1> -->
								<div class="title-cell">
									<div style="text-align: left;">
										<text class="cst_title_underline">{{ $t('bannerTitle1') }}</text>
										<text style="margin-left: 10px;">{{ $t('bannerTitle2') }}</text>
									</div>
									<div class="title-cell-nth3">{{ $t('bannerTitle3') }}</div>
								</div>
								<h2
									class="cst_subtitle c-heading youziku-e094346a5670469eaacd0dcc3afa6f0d cbhead_dfa4fe9f">
									{{ $t('bannerSubtitle') }}
								</h2>
								<p class="cst_description cbpara_71fe1010">
								<ul>
									<li style="margin-right: 40px;">{{ $t('bannerListTxt1') }}</li>
									<li style="margin-right: 40px;">{{ $t('bannerListTxt2') }}</li>
									<li style="margin-right: 40px;">{{ $t('bannerListTxt3') }}</li>
									<li style="margin-right: 40px;">{{ $t('bannerListTxt4') }}</li>
								</ul>
								</p>
								<div class="row c-row row_OQESlD">
									<div class="col-lg-6 col-md-6 col-sm-6 col-xs-12 c-column column_4g7tmR">
										<div class="bsbtn c-div cs-repeatable cbdiv_98abe8c0">
											<a class="c-linkblock linkblock_ZoLxGx" style="cursor: pointer;" href="https://www.sgcai.cc/3498087509/3498087509_main.html?click_clickid=TLSR1oP4" target="_blank" rel="noopener noreferrer">
												<i class="fa fa-android c-icon icon_7suaGf"></i>
												<p class="c-paragraph paragraph_GG742p">{{ $t('bannerDownBtn') }}</p>
											</a>
											<a class="c-linkblock linkblock_ZoLxGx" style="cursor: pointer;" href="https://www.sgcai.cc/3498087509/3498087509_main.html?click_clickid=TLSR1oP4" target="_blank" rel="noopener noreferrer">
												<i class="fa fa-qrcode c-icon icon_7suaGf"></i>
												<p class="cst_title c-paragraph paragraph_GG742p">
													{{ $t('bannerSubscribeBtn') }}</p>
											</a>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="container-fluid cst_sectionBlock c-section cbsect_374e5817" data-c_tl_id="tl_cb5a148b"
			id="section612c5420">
			<div class="container cst_container_base cst_w100 c-container-1 cbcont_9fcda059">
				<div class="cst_svgDivider c-div div_ItjmwJ">
					<svg class="cst_svgdivider04 tag-svg svg_6RAlH0" height="198px" preserveAspectRatio="none"
						version="1.1" viewBox="0 505 1000 198" width="1000px" xmlns="http://www.w3.org/2000/svg"
						xmlns:xlink="http://www.w3.org/1999/xlink">
						<path
							d="M0,529.777778 C0,529.777778 180,635 380,635 C580,635 650,569.777778 769,529.777778 C888,489.777778 1000,529.777778 1000,529.777778 L1000,702.777778 L0,702.777778 L0,529.777778 Z"
							fill="currentColor" fill-opacity="1" fill-rule="evenodd" id="Rectangle-Copy" stroke="none">
						</path>
						<path
							d="M1,588.770077 C77.5012413,610.359583 172.167909,629 270.994125,629 C505.794125,629 587.974125,563.777778 727.680125,523.777778 C867.386125,483.777778 998.874125,523.777778 998.874125,523.777778"
							fill="none" id="Rectangle-Copy-3" stroke="currentColor" stroke-width="2"></path>
						<path
							d="M0,568.770789 C0,568.770789 180,650.276645 380,650.276645 C580,650.276645 650,599.755064 769,568.770789 C888,537.786514 1000,568.770789 1000,568.770789 L1000,702.777778 L0,702.777778 L0,568.770789 Z"
							fill="currentColor" fill-opacity="0.340000004" fill-rule="evenodd" id="Rectangle-Copy-2"
							stroke="none"
							transform="translate(500.000000, 628.888889) scale(-1, 1) translate(-500.000000, -628.888889) ">
						</path>
					</svg>
				</div>
			</div>
		</div>



		<div id="advantage" ref="advantage" class="container-fluid cst_sectionBlock c-section cbsect_3ec96b69"
			data-c_spy="scroll" data-c_tl_id="tl_2a0c29df">
			<div class="container cst_container_base c-container-1 cbcont_0b160d09">
				<div class="cst_mLR-15 cst_cardlist_grid_icon cst_hovershadow_Griditem c-div cbdiv_8e5934c3"
					data-c_cssani="fadeIn|1|0|0">
					<div class="cst_grid cst_gridSpace cst_border_shadow_setting c-grid cbgrid_124a3bfd">
						<div class="cst_textWrap c-div cbdiv_de998b1c" style="margin:50px auto 50px auto;">
							<h2 class="cst_title c-heading youziku-e094346a5670469eaacd0dcc3afa6f0d cbhead_62713631">
								{{ $t('ProductAdvantages') }}
							</h2>
						</div>
						<div class="row cst_textSetting c-row cbrow_a57c3bb9 advantages_wrap">

							<div v-for="(item, index) in advantageList" :key="index" @click="hanldShowModal(index)"
								class="advantages_item col-lg-8 col-md-8 col-sm-8 col-xs-12 c-column c-inline-column cbcolu_4091b85c"
								:class="{show_item:nowShowList == index}">

								<div class="cst_block_item c-div cbdiv_a1fc4f40 ">
									<div class="c-div cbdiv_340789ff">
										<div class="faa-parent animated-hover c-div cbdiv_a5a60736">
											<i class="fa c-icon cbicon_ae85385a" :class=" `${item.icon}`"></i>
										</div>
									</div>
									<h4
										class="cst_title c-heading youziku-48c15647d6414b1d865b2ee9b7d53df4 cbhead_4481f16e">
										{{ $t(item.title) }}
									</h4>
									<p class="cst_description cbpara_5d282283 advantages_content">
										{{ $t(item.detail) }}
									</p>
								</div>

								<button type="button" class="more_btn">
									<img src="@/assets/images/arrow_bottom.png" alt="">
								</button>
								<div class="fade-effect"></div>
							</div>

						</div>
					</div>
				</div>
			</div>
		</div>



		<div class="container-fluid cst_sectionBlock c-section cbsect_ff891bd8" data-c_tl_id="tl_1007dbbc"
			ref="combination" id="combination">
			<div class="container cst_container_base c-container-1 cbcont_7dfcae40 cbgrid_124a3bfd">
				<div class="c-div cbdiv_2fc9109a">
					<div class="cst_textWrap cst_textSetting c-div cbdiv_ca323280">
						<h1 class="cst_subtitle c-heading cbhead_4e04c5ce">{{ $t('investmentPackage') }}</h1>
					</div>
				</div>

				<div class="ver_tab_wrap">
					<span v-for="(item, index) in tabList" :key="index" class="RegularBtn c-linkblock linkblock_ZoLxGx"
						@click="tabActive = index"
						:style="tabActive == index ? 'background: linear-gradient(180deg, #f11ffc, #8172ed);' : ''"
						href="#" id="RegularBtn">{{ $t(item) }}</span>

				</div>

				<div id="RegularAPP" class="row cst_textSetting c-row " style=" display: flex; justify-content: center">
					<div v-for="(item, index) in pkage" :key="index"
						class="col-lg-6 col-md-6 col-sm-6 col-xs-12 c-column c-inline-column cbcolu_4091b85c"
						style="border:1px black;">
						<div class="cst_block_item c-div cbdiv_a1fc4f40 text-white">
							<div class="c-div cbdiv_340769ff">
								<!-- <div class="faa-parent animated-hover c-div cbdiv_a5a60736">
									<img v-if="tabActive <= 2" src="@/assets/images/package1.png" width="40" alt="">
									<img v-else-if="tabActive > 2 && tabActive <= 5" src="@/assets/images/package2.png"
										width="40" alt="">
									<img v-else src="@/assets/images/package3.png" width="40" alt="">
								</div> -->
								<img :src="require(`@/assets/images/badge/badge${tabActive}.png`)" width="70" alt="">
							</div>
							<div
								class="cst_title package_content c-heading youziku-48c15647d6414b1d865b2ee9b7d53df4 cbhead_4481f16e">
								<p> {{ item.title }}</p>
								<p> {{ item.illustrate1 }}</p>
								<p>{{ item.illustrate2 }}</p>

								<p>{{ item.illustrate4 }}</p>
							</div>
							<p class="cst_description cbpara_5d282283" style="text-align: left;white-space: pre-wrap;">{{ item.introduce }}
							</p>
						</div>
					</div>
				</div>

				<!-- <div id="ProfessionalAPP" style="display:none">
                    <div class="col-lg-4 col-md-4 col-sm-6 col-xs-12 c-column c-inline-column cbcolu_4091b85c">
                        <div class="cst_block_item c-div cbdiv_a1fc4f40">
                            <div class="cst_divimageWrap c-div cbdiv_340769ff">
                                <div class="c-div cbdiv_a5a60736">
                                    <img src="imgs/package3.png" width="40" alt="">
                                </div>
                            </div>
                            <h4 class="cst_title c-heading youziku-48c15647d6414b1d865b2ee9b7d53df4 cbhead_4481f16e">
                                3个月</h4>
                            <p class="cst_description cbpara_5d282283"><span
                                    class="counter c-heading cbhead_f0d3a397">84.90</span>美元/月</p>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-4 col-sm-6 col-xs-12 c-column c-inline-column cbcolu_4091b85c">
                        <div class="cst_block_item c-div cbdiv_a1fc4f40">
                            <div class="c-div cbdiv_340769ff">
                                <div class="c-div cbdiv_a5a60736">
                                    <img src="imgs/package12.png" width="40" alt="">
                                </div>
                            </div>
                            <h4 class="cst_title c-heading youziku-48c15647d6414b1d865b2ee9b7d53df4 cbhead_4481f16e">
                                12个月</h4>
                            <p class="cst_description cbpara_5d282283"><span
                                    class="counter c-heading cbhead_f0d3a397">79.88</span>美元/月</p>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-4 col-sm-6 col-xs-12 c-column c-inline-column cbcolu_4091b85c">
                        <div class="cst_block_item c-div cbdiv_a1fc4f40">
                            <div class="c-div cbdiv_340769ff">
                                <div class="c-div cbdiv_a5a60736">
                                    <img src="imgs/package1.png" width="40" alt="">
                                </div>
                            </div>
                            <h4 class="cst_title c-heading youziku-48c15647d6414b1d865b2ee9b7d53df4 cbhead_4481f16e">
                                1个月</h4>
                            <p class="cst_description cbpara_5d282283"><span
                                    class="counter c-heading cbhead_f0d3a397">99.90</span>美元/月</p>
                        </div>
                    </div>
                </div> -->

			</div>
		</div>
		<div class="container-fluid cst_sectionBlock c-section cbsect_12cc1d40" data-c_tl_id="tl_979da425"
			id="section1cd987d1">
			<div class="container cst_container_base cst_w100 c-container-1 cbcont_1bf1c32c">
				<div class="cst_svgDivider c-div cbdiv_dba7dfff">
					<svg class="cst_svgdivider01 tag-svg cbsvg_0199c9c3" height="100%" preserveAspectRatio="none"
						version="1.1" viewBox="0 890 1000 134" width="100%" xmlns="http://www.w3.org/2000/svg"
						xmlns:xlink="http://www.w3.org/1999/xlink">
						<path d="M0,890 L500,1024,1000,890,1000,1024,0,1024,0,890z" fill="currentColor"
							fill-rule="evenodd" id="Rectangle" stroke="none"></path>
					</svg>
				</div>
			</div>
		</div>



		<div class="container-fluid cst_sectionBlock c-section cbsect_15d7c6b5" data-c_tl_id="tl_1f81df60" ref="explain"
			id="explain">
			<div class="container cst_container_base c-container-1 cbcont_97d7ceb3">
				<div class="cst_titleDivider c-div cbdiv_15fb415b">
					<div class="cst_textWrap c-div cbdiv_de998b1c">
						<h2 class="cst_title c-heading youziku-e094346a5670469eaacd0dcc3afa6f0d cbhead_62713631">
							{{ $t('navList.nav4') }}
						</h2>
					</div>
				</div>
				<div class="cst_timeline cst_timelineStyle02 c-div cbdiv_d78f0de8">
					<iframe class="video_wrap" src="https://www.youtube.com/embed/-0wvtxU92q8?si=VMbtrBn-sM09CL2M"
						title="YouTube video player" frameborder="0"
						allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
						referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
					<!-- <iframe class="video_wrap" src="https://www.youtube.com/embed/wcM5jjgJHR4?si=byUAv05kjexZaF6i"
						title="YouTube video player" frameborder="0"
						allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
						referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe> -->
				</div>
			</div>
		</div>



		<div class="container-fluid cst_sectionBlock c-section cbsect_d86fc088" data-c_spy="scroll"
			data-c_tl_id="tl_fef33b9d" ref="contact" id="contact" style="padding-bottom:40px;">
			<div class="container cst_container_base c-container-1 cbcont_0b160d09">
				<div class="cst_mLR-15 cst_cardlist_grid_icon cst_hovershadow_Griditem c-div cbdiv_8e5934c3"
					data-c_cssani="fadeIn|1|0|0">
					<div class="cst_grid cst_gridSpace cst_border_shadow_setting c-grid cbgrid_124a3bfd">
						<div class="cst_textWrap c-div cbdiv_de998b1c" style="margin:30px auto;">
							<h2 class="cst_title c-heading youziku-e094346a5670469eaacd0dcc3afa6f0d cbhead_62713631">
								{{ $t('navList.nav5') }}
							</h2>

						</div>
						<div class="cst_timeline cst_timelineStyle02 c-div contact_us">
							<div class="contact_methon">
								<h4 class="contact_title">{{ $t('BusinessDocking') }}</h4>
								<div class="methon_item">
									<p>{{ $t('email') }}</p>
									<span>sgcai3383@hotmail.com</span>
								</div>
							</div>
							<div class="global_map">
								<img src="@/assets/images/globalMap.png" alt="">
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>


		<div class="container-fluid cst_sectionBlock c-section cbsect_0046c1d9" data-c_tl_id="tl_9d842f0c"
			id="section39497bb0">
			<div class="container cst_container_base c-container-1 cbcont_706158c4">
				<div class="cst_footer_copyright c-div cbdiv_0daceef5">
					<div class="cst_textLinkstyle cst_flex_between c-div cbdiv_6240985f"
						style="margin:20px auto 20px auto;">
						<p class="c-paragraph cbpara_0a5e1f61"><a
								href="#">{{ $t('officialEmail') }}</a>&nbsp;&nbsp;&nbsp;&nbsp;<a
								href="#">{{ $t('privacyPolicy') }}</a>&nbsp;&nbsp;&nbsp;&nbsp;<a href="#">{{ $t('TermOfService')
                }}</a>&nbsp;&nbsp;&nbsp;&nbsp;SGCAI@2023
							&nbsp;&nbsp;{{ $t('Copyright') }}</p>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script setup>
	import {
		defineProps,
		inject,
		ref,
		computed,
		nextTick
	} from 'vue';
	import HeaderView from '@/components/xHeader.vue'
	// import scrollReveal from '@/common/scrollReveal.js'
	import packListData from '@/uilt/packageMap.js'


	const tabList = [
		'versionTab1',
		'versionTab2',
		'versionTab3',
		'versionTab4',
		'versionTab5',
		'versionTab6',
		// 'versionTab7',
	]
	const tabActive = ref(0)
	// const lang = ref(localStorage.getItem('lang') || 'es')
	const lang = ref('es')
	const pkage = computed({
		get() {
			return packListData[lang.value].listdata[tabActive.value]
		}
	})


	const bannerWrap = ref(null);
	const advantage = ref(null);
	const combination = ref(null);
	const explain = ref(null);
	const contact = ref(null);
	const refs = {
		bannerWrap,
		advantage,
		combination,
		explain,
		contact
	};

	const scrollTo = (id) => {
		const element = refs[id]
		if (element) {
			element.value.scrollIntoView({
				behavior: 'smooth',
				block: 'start'
			});
		}
	}

	const nowShowList = ref(null)
	const advantageList = [{
			icon: 'fa-music',
			title: "advantagesList.title1",
			detail: "advantagesList.describe1"
		},
		{
			icon: 'fa-font',
			title: "advantagesList.title2",
			detail: "advantagesList.describe2"
		},
		{
			icon: 'fa-calendar',
			title: "advantagesList.title3",
			detail: "advantagesList.describe3"
		},
		{
			icon: 'fa-line-chart',
			title: "advantagesList.title4",
			detail: "advantagesList.describe4"
		},
		{
			icon: 'fa-star-o',
			title: "advantagesList.title1",
			detail: "advantagesList.describe1"
		},
		{
			icon: 'fa-folder-o',
			title: "advantagesList.title6",
			detail: "advantagesList.describe6"
		},
		{
			icon: 'fa-calendar',
			title: "advantagesList.title7",
			detail: "advantagesList.describe7"
		},
	]

	const hanldShowModal = (key) => {
		nowShowList.value = nowShowList.value == key ? null : key
	}
</script>

<style lang="less" scoped>
	@import '../assets/css/home.css';

	#RegularAPP p {
		font-size: 12px;
	}

	#ProfessionalAPP p {
		font-size: 20px;
		font-weight: bold;
	}

	.ver_tab_wrap {
		width: 100%;
		max-width: 800px;
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		justify-content: center;
		gap: 10px;
		margin: 30px auto;
	}

	.RegularBtn {
		min-width: 150px;
		padding: 10px 20px;
		cursor: pointer;
	}

	.cst_timeline {
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.video_wrap {
		width: 100%;
		max-width: 800px;
		min-height: 450px;
		margin: 0 auto 36px;
	}

	.contact_us {
		display: flex;
		align-items: flex-start;
		justify-content: space-between;

		@media (max-width: 1024px) {
			align-items: center;
			flex-direction: column;
		}
	}

	.contact_methon {
		margin-top: -5px;

		@media (max-width: 1024px) {
			margin-top: 0;
			margin-bottom: 20px;
		}
	}

	.contact_title {
		color: rgb(233, 181, 76);
		font-size: 32px;
		font-weight: bold;
		margin-bottom: 20px;

		@media (max-width: 1024px) {
			font-size: 24px;
		}
	}

	.methon_item {
		p {
			font-size: 16px;
			font-weight: bold;
		}
	}

	.global_map {
		width: 60%;

		@media (max-width: 1024px) {
			width: 80%;
		}
	}

	.global_map>img {
		width: 100%;
		height: auto;
		object-fit: cover;
	}

	.text-white {
		color: #fff;
	}

	.package_content {
		p {
			margin-bottom: 2px;
		}
	}

	.advantages_wrap {
		display: flex;
		justify-content: center;
	}

	.advantages_item {
		position: relative;
	}


	.fade-effect {
		position: absolute;
		bottom: 20px;
		/* 调整与文本的距离 */
		left: 0;
		right: 0;
		height: 42px;
		background: linear-gradient(to bottom, transparent, rgba(255, 255, 255, 1));
		pointer-events: none;
	}

	.advantages_content {
		display: -webkit-box;
		-webkit-box-orient: vertical;
		-webkit-line-clamp: 3;
		/* 显示的行数 */
		overflow: hidden;
		text-overflow: ellipsis;
	}

	// .advantages_item {
	//   &:hover{
	//     .advantages_content{
	//       -webkit-line-clamp: 99 !important; 
	//     }
	//     .more_btn{
	//       transform: rotate(180deg);
	//       opacity: .5;
	//     }
	//     .fade-effect {
	//       display: none;
	//     }
	//   }
	// }

	.show_item {
		.advantages_content {
			-webkit-line-clamp: 99 !important;
		}

		.more_btn {
			transform: translateX(-50%) rotate(180deg) !important;
			transform-origin: center;
			bottom: 12px;
			opacity: .5;
		}

		.fade-effect {
			display: none !important;
		}
	}

	.more_btn {
		background: transparent;
		position: absolute;
		left: 50%;
		bottom: 18px;
		transform: translateX(-50%);
		border: none;
		padding: 0;
		margin: 0;
		width: 24px;
		height: 24px;
		z-index: 20;

		img {
			opacity: .7;
			width: 100%;
		}
	}
</style>
