export default {
    navList: {
        nav1: 'Home',
        nav2: 'Vantaggi',
        nav3: 'Portafoglio',
        nav4: 'Video AI',
        nav5: 'Contatto',
        login: 'Accedi',
        register: 'Registrati'
    },
    Language: "Lingua",
    down: 'Scarica',
    Menu: 'Menu',
    bannerTitle: '【SGC AI】Trading Quantitativo',
    bannerSubtitle: 'Rendimento Stabile',
    bannerListTxt1: 'Ribilanciamento',
    bannerListTxt2: 'Gestione Portafoglio',
    bannerListTxt3: 'Raccolta Dati',
    bannerListTxt4: 'Instradamento Intelligente',
    bannerDownBtn: 'Scarica Android',
    bannerSubscribeBtn: 'Abbonati Ora',
    ProductAdvantages: 'Vantaggi vs Umani',
    advantagesList: {
        title1: 'La differenza tra vantaggi del prodotto e manodopera',
        describe1: "I vantaggi progettuali dei modelli di strategia quantitativa AI. Ogni modello quantitativo AI è composto da più di centinaia di fattori che possono influenzare l'aumento e la caduta delle azioni. Ha capacità di deep learning indipendenti e può tenere il passo con il flusso principale di fondi. È difficile che l’analisi e la ricerca manuale soddisfino tali requisiti.",
        title2: 'Vantaggi della funzione di test di restituzione dei dati storici',
        describe2: "Per progettare un modello quantitativo AI con un tasso di rendimento che supera di gran lunga il tasso di rendimento del mercato, è possibile ottenere il tasso di rendimento annualizzato storico del modello eseguendo il backtest dei dati del ciclo storico. Da loro è possibile selezionare il modello più ideale. Questo è semplicemente impossibile da fare manualmente, non importa quanto tempo ci vuole. Pertanto, le operazioni manuali spesso non sono rigorose e non scientifiche e si basano solo sulle sensazioni e sull'esperienza personali per selezionare gli obiettivi da operare.",
        title3: 'Vantaggi della scansione automatica di oltre 400.000 titoli nel mercato globale',
        describe3: "Il robot AI scansiona automaticamente più di 400.000 azioni dell'intero mercato durante il giorno in base alle condizioni e ad altri parametri impostati dai tecnici del modello quantitativo e completa immediatamente l'operazione quando rileva che le condizioni target impostate sono soddisfatte. Ci vogliono solo pochi secondi. L’analisi manuale richiederebbe anni o addirittura decenni e non sarebbe in grado di completare la stessa quantità di lavoro.",
        title4: 'Solo comprendendo la storia possiamo giudicare il futuro in modo più accurato',
        describe4: 'Il tasso di rendimento del backtest dei dati storici del modello quantitativo può essere utilizzato come riferimento migliore per giudicare i tassi di rendimento futuri. Gli ingegneri tecnici devono solo inserire direttamente i parametri nella direzione delle azioni acquistate in quel giorno e possono eseguire il backtest del tasso di rendimento giornaliero del titolo al punto di acquisto. Per soddisfare questi requisiti, il funzionamento manuale è semplicemente impossibile.',
        title5: 'Stabilire un modello di profitto stabile e a lungo termine',
        describe5: 'Per ottenere profitti stabili a lungo termine ed enormi interessi composti, gli ingegneri tecnici possono personalizzare e aggiungere i parametri degli indicatori selezionati, verificare il tasso di rendimento tramite test retrospettivi, selezionare la strategia o la combinazione più ideale e quindi condurre operazioni di trading completamente automatiche con robot IA. Questo tipo di lavoro non può essere eseguito manualmente.',
        title6: 'Capacità di esecuzione operativa',
        describe6: "Il vantaggio del trading quantitativo completamente automatico di SGC AI è che utilizza strumenti razionali per gestire le transazioni in base alle impostazioni del modello. Il robot non ha cambiamenti emotivi e può superare la natura umana, l'avidità e la paura umane e molte altre carenze e debolezze nel processo operativo. Le operazioni manuali sono spesso influenzate dall'ascesa e dalla caduta del mercato e da vari altri fattori e non possono essere gestite a lungo secondo il miglior modello di profitto.",
        title7: 'Liberare completamente la produttività',
        describe7: "Il robot quantitativo SGC AI può essere impostato una volta ed eseguire automaticamente le operazioni in base alle impostazioni per un lungo periodo. È sufficiente eseguire il processo di allocazione del capitale una volta al giorno, liberando completamente gli utenti dall'arduo lavoro di negoziazione di azioni. Tuttavia, l’analisi e la ricerca manuale, il monitoraggio del mercato e il trading manuale affidato richiedono molto tempo ed energia da parte degli investitori.",
    },
    investmentPackage: "Scegli il tuo Portafoglio",
    versionTab1: 'Base',
    versionTab2: 'Iniziale',
    versionTab3: 'Standard',
    versionTab4: 'Avanzato',
    versionTab5: 'Pro',
    versionTab6: 'Aziendale',
    versionTab7: 'Personalizzato',
    BusinessDocking: 'Collegamento Commerciale',
    email: "E-mail",
    officialEmail: 'E-mail Ufficiale',
    privacyPolicy: 'Politica sulla Privacy',
    TermOfService: "Termini di Servizio",
    Copyright: 'Diritto d’Autore'
}