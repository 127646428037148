export default {
    navList: {
        nav1: 'Home',
        nav2: 'Advantages',
        nav3: 'Portfolio',
        nav4: 'AI Videos',
        nav5: 'Contact',
        login: 'Login',
        register: 'Register'
    },
    Language: "Language",
    down: 'Download',
    Menu: 'Menu',
    bannerTitle: '[SGC AI] Quantitative Trading',
    bannerSubtitle: 'Stable and Sustainable Returns',
    bannerListTxt1: 'Rebalancing',
    bannerListTxt2: 'Portfolio Management',
    bannerListTxt3: 'Data Collection',
    bannerListTxt4: 'Smart Order Routing',
    bannerDownBtn: 'Android Download',
    bannerSubscribeBtn: 'Subscribe Now',

    ProductAdvantages: 'Product Advantages and Differences from Human Analysis',
    advantagesList: {
        title1: 'Advantages of AI Quantitative Strategy Model Design',
        describe1: 'Each AI quantitative model is composed of hundreds of factors that can influence stock price fluctuations. It has the ability to learn deeply and follow the direction of major funds, which is difficult for human analysis to achieve.',
        title2: 'Advantages of Historical Data Yield Backtesting',
        describe2: 'To design an AI quantitative model with a yield that far exceeds the market yield, historical cyclic data can be backtested to determine the historical annualized yield of the model. The most ideal model can then be selected. This is something that human operations simply cannot achieve, no matter how much time is spent. Therefore, human operations are often not rigorous, unscientific, and based solely on personal feelings and experience.',
        title3: 'Advantages of Automatically Scanning Over 400,000 Stocks Globally',
        describe3: 'AI robots scan over 400,000 stocks globally throughout the day based on the conditions set by technical personnel and other parameters. When conditions that meet the criteria are found, the operation is immediately completed. This takes only a few seconds, whereas it would take years, even decades, for human analysis to achieve the same workload.',
        title4: 'Understanding History to Better Predict the Future',
        describe4: 'The historical data yield backtesting of quantitative models can be used as a reference for better predicting future yields. Technical engineers only need to input parameters directly in the direction of the stocks purchased on the day to backtest the daily yield of that stock at the buying point. To meet these requirements, it is impossible to achieve with human operations.',
        title5: 'Establishing a Long-Term Stable Profit Model',
        describe5: 'To achieve long-term stable profits and significant compound interest, technical engineers can customize and add the parameters they select, backtest and verify their yield, and then choose the most ideal strategy or combination to perform fully automated AI trading. Such work cannot be done through manual trading.',
        title6: 'Execution Capability',
        describe6: 'The advantage of SGC AI fully automated quantitative trading is that it uses rational tools to operate trades according to the model settings. The robot does not have emotional fluctuations and can overcome human nature, greed, fear, and many other shortcomings in the operation process. Human operations, however, are often affected by market fluctuations and various other factors, making it difficult to operate consistently according to the best profit model.',
        title7: 'Completely Liberating Productivity',
        describe7: 'The SGC AI quantitative robot can perform operations automatically according to the set parameters for a long time after a one-time setup. Users only need to perform a capital allocation process once a day, freeing them from the burdensome stock trading work. In contrast, human analysis, monitoring the market, and manual order placement require a large amount of time and effort from investors.',
    },
    investmentPackage: "Choose Your Investment Portfolio",
    versionTab1: 'Basic Version',
    versionTab2: 'Entry Version',
    versionTab3: 'Standard Version',
    versionTab4: 'Advanced Version',
    versionTab5: 'Professional Version',
    versionTab6: 'Enterprise Version',
    versionTab7: 'Customized Version',
    BusinessDocking: 'Business Docking',
    email: "Email",
    officialEmail: 'Official Email',
    privacyPolicy: 'Privacy Policy',
    TermOfService: "Terms of Service",
    Copyright: 'All Rights Reserved'
}