export default {
    navList: {
        nav1: 'Strona Główna',
        nav2: 'Zalety',
        nav3: 'Portfel',
        nav4: 'Filmy AI',
        nav5: 'Kontakt',
        login: 'Zaloguj się',
        register: 'Zarejestruj się'
    },
    Language: "Język",
    down: 'Pobierz',
    Menu: 'Menu',
    bannerTitle: '【SGC AI】Handel Ilościowy',
    bannerSubtitle: 'Stabilny Dochód',
    bannerListTxt1: 'Rebalansowanie',
    bannerListTxt2: 'Zarządzanie Portfelem',
    bannerListTxt3: 'Zbieranie Danych',
    bannerListTxt4: 'Inteligentne Routing Zamówień',
    bannerDownBtn: 'Pobierz na Androida',
    bannerSubscribeBtn: 'Subskrybuj Teraz',
    ProductAdvantages: 'Zalety vs Ludzkie',
    advantagesList: {
        title1: 'Różnica między zaletami produktu a pracą',
        describe1: 'Zalety konstrukcyjne modeli strategii ilościowych AI Każdy model ilościowy AI składa się z ponad setek czynników, które mogą wpływać na wzrost i spadek zapasów. Posiada niezależne możliwości głębokiego uczenia się i może nadążać za głównym przepływem funduszy. Ręczne analizy i badania trudno sprostać takim wymaganiom.',
        title2: 'Zalety funkcji testowania zwrotu danych historycznych',
        describe2: 'Aby zaprojektować model ilościowy AI ze stopą zwrotu znacznie przekraczającą rynkową stopę zwrotu, można uzyskać historyczną roczną stopę zwrotu modelu, weryfikując dane historyczne z cyklu. Można spośród nich wybrać najbardziej idealny model. Nie da się tego zrobić ręcznie, niezależnie od tego, ile czasu to zajmie. Dlatego operacje ręczne często nie są rygorystyczne i nienaukowe, a przy wyborze celów do operacji opierają się wyłącznie na osobistych odczuciach i doświadczeniu.',
        title3: 'Zalety automatycznego skanowania ponad 400 000 akcji na rynku światowym',
        describe3: 'Robot AI automatycznie skanuje w ciągu dnia ponad 400 000 akcji na całym rynku zgodnie z warunkami i innymi ustawionymi parametrami ustawionymi przez techników zajmujących się modelami ilościowymi i natychmiast kończy operację, gdy stwierdzi, że spełnione zostały założone warunki docelowe. Zajmuje to tylko kilka sekund. Ręczna analiza zajęłaby lata, a nawet dziesięciolecia i nie byłaby w stanie wykonać tak dużego nakładu pracy.',
        title4: 'Tylko poprzez zrozumienie historii możemy trafniej ocenić przyszłość',
        describe4: 'Dane historyczne potwierdzające stopę zwrotu z modelu ilościowego można wykorzystać jako lepsze odniesienie do oceny przyszłych stóp zwrotu. Inżynierowie techniczni muszą jedynie bezpośrednio wprowadzić parametry dotyczące akcji zakupionych tego dnia i mogą przeprowadzić test historyczny dziennej stopy zwrotu. zapasów w punkcie zakupu. Aby spełnić te wymagania, obsługa ręczna jest po prostu niemożliwa do osiągnięcia',
        title5: 'Stwórz długoterminowy i stabilny model zysku',
        describe5: 'Aby uzyskać długoterminowe stabilne zyski i ogromne odsetki składane, inżynierowie techniczni mogą dostosowywać i dodawać własne wybrane parametry wskaźników, weryfikować stopę zwrotu poprzez weryfikację historyczną, wybierać najbardziej idealną strategię lub kombinację, a następnie przeprowadzać w pełni automatyczny handel za pomocą robotów AI. Tego rodzaju pracy nie można wykonać ręcznie.',
        title6: 'Możliwość wykonania operacyjnego',
        describe6: 'Zaletą w pełni automatycznego handlu ilościowego SGC AI jest to, że wykorzystuje racjonalne narzędzia do obsługi transakcji zgodnie z ustawieniami modelu. Robot nie ulega zmianom emocjonalnym i może przezwyciężyć ludzką naturę, ludzką chciwość i strach oraz wiele innych niedociągnięć i słabości w procesie operacyjnym. Na operacje ręczne często wpływa wzrost i spadek rynku oraz różne inne czynniki i przez długi czas nie można ich prowadzić zgodnie z modelem najlepszego zysku.',
        title7: 'Całkowicie uwolnij produktywność',
        describe7: 'Robot ilościowy SGC AI można ustawić raz i automatycznie wykonywać operacje zgodnie z ustawieniami przez długi czas. Wystarczy raz dziennie przeprowadzić proces alokacji kapitału, co może całkowicie uwolnić użytkowników od żmudnej pracy związanej z obrotem akcjami. Jednakże ręczne analizy i badania, śledzenie rynku i ręczny handel powierzony wymagają od inwestorów dużo czasu i energii.',
    },
    investmentPackage: "Wybierz swój Portfel",
    versionTab1: 'Podstawowa',
    versionTab2: 'Początkowa',
    versionTab3: 'Standardowa',
    versionTab4: 'Zaawansowana',
    versionTab5: 'Profesjonalna',
    versionTab6: 'Firmowa',
    versionTab7: 'Dostosowana',
    BusinessDocking: 'Kontakt Biznesowy',
    email: "E-mail",
    officialEmail: 'Oficjalny E-mail',
    privacyPolicy: 'Polityka Prywatności',
    TermOfService: "Warunki Usługi",
    Copyright: 'Prawa Autorskie'
}